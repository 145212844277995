import { createGlobalStyle } from './themed-component';
import { mediaOnly } from './breakpoints';


export const ZoneGlobalStyle = createGlobalStyle`

  /***************************
   ****  START CSS Reset  ****
   ***************************/

  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /*************************
   ****  END CSS Reset  ****
   *************************/


  /* fira-sans-regular - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
    url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/fira-sans/fira-sans-v10-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Medium.ttf');
  }

  @font-face {
    font-family: 'Roboto Light';
    src: url('/assets/fonts/roboto/Roboto-Light.ttf');
  }

  @font-face {
    font-family: 'Catamaran';
    src: url('/assets/fonts/catamaran/Catamaran-Regular.ttf');
  }

  @font-face {
    font-family: 'Catamaran Light';
    src: url('/assets/fonts/catamaran/Catamaran-Light.ttf');
  }

  .font-light {
    /* font: 1.156em/1.5 "Roboto Light", Gill Sans, My Gill Sans, sans-serif; */
    font: 1rem "Catamaran Light", Gill Sans, My Gill Sans, sans-serif;
  }

  body {
    font: ${p => p.theme.text.defaultFont ?? 'inherit'};
    background-color: ${p => p.theme.bgColor}
  }

  a {
    color: ${p => p.theme.colors.primary};
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
  }

  ${mediaOnly('small')} {
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #F5F5F5;
    } 
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #858585;
  }

  ::selection {
    background: ${p => p.theme.colors.primary}; /* WebKit/Blink Browsers */
    color: white;
  }
  
  ::-moz-selection {
    background: ${p => p.theme.colors.primary}; /* Gecko Browsers */
    color: white;
  }

  .highlighted {
    color: ${p => p.theme.colors.primary}
  }
`;
