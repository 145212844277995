import { isDevEnv } from "@core-lib/lib/util";

export type Credentials = { email: string, password: string};


export const LAST_LOGIN_RESULT_KEY = 'explorer-auth-last-login'



const getApiHost = () => {
  let host = 'api.conact.zone';
  if (location.host.endsWith('.online')) {
    host = 'api.conact.online';
  }
  return host;
}

export const isAuthenticated = () => {
  if (isDevEnv()) return true;
  return window.localStorage.getItem(LAST_LOGIN_RESULT_KEY) === 'success';
}


export const logout = async () => {
  await fetch('/service-workers/auth/logout', {
    method: 'POST'
  });
  window.localStorage.clear();

  window.location.reload();
}

export type LoginResult = 'success' | 'unauthorized' | 'network-error'

export const login = async (creds: Credentials): Promise<LoginResult> => {
  try {
    // @TODO(auth-dev -> dev)
    // const resp = await fetch('https://api.conact.zone/auth/login', {
    const resp = await fetch(`https://${getApiHost()}/auth/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(creds),
      mode: 'cors'
    });

    // if (resp?.body) {
    //   console.log("resp body ist da");
    //   const respBody = await resp.json() as any;
    //   if (respBody?.error?.statusCode === 401) {
    //     console.log("nicht autorisiert 401");
    //     console.log("statusCode ", respBody?.error?.statusCode);
    //     window.localStorage.setItem(LAST_LOGIN_RESULT_KEY, 'unauthorized');
    //     return 'unauthorized';
    //   }
    // }
    if (resp.status === 200) {
      window.localStorage.setItem(LAST_LOGIN_RESULT_KEY, 'success');
      return 'success';
    }
    else {
      // console.log(resp.status);
      window.localStorage.setItem(LAST_LOGIN_RESULT_KEY, 'unauthorized');
      return 'unauthorized';
    };

  } catch (err) {
    // console.log("errr gecatched", err)
    window.localStorage.setItem(LAST_LOGIN_RESULT_KEY, 'network-error');
    return 'network-error'
    //im cluster wird fehler geworfen und kein status gesetzt wenn nicht 200.....
    // window.localStorage.setItem(LAST_LOGIN_RESULT_KEY, 'unauthorized');
    // return 'unauthorized';
  }
}

export type RequestPasswordResetResult = 'success' | 'failed' | 'network-error'


export const requestPasswordReset = async (email: String, host: string) => {
  try {
    // @TODO(auth-dev -> dev)
    // const resp = await fetch('https://api.conact.zone/auth/request-password-reset', {
    const resp = await fetch(`https://${getApiHost()}/auth/request-password-reset`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, host }),
      mode: 'cors'
    });

    if (resp.status === 200) return 'success';
    else return 'failed'

  } catch (err) {
    return 'network-error'
  }
}


export type ResetPasswordResult = 'success' | 'failed' | 'token-expired' | 'network-error'

export const resetPassword = async (payload: Credentials & { resetToken: string }): Promise<ResetPasswordResult> => {
  try {
    // @TODO(auth-dev -> dev)

    // const resp = await fetch('https://api.conact.zone/auth/reset-password', {
    const resp = await fetch(`https://${getApiHost()}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
      mode: 'cors'
    });

    if (resp.status === 200) return 'success';
    if (resp.status === 401) return 'token-expired';
    else return 'failed'

  } catch (err) {
    return 'network-error'
  }
}