import { Theme, ThemeColors, ThemeSizes } from "./spec";


const Colors: ThemeColors = {
  // primary: '#96BD0D',
  primary: '#46b9a0',
  secondary: '#F1F1F1',
  // secondary: '#3d3d3d',
  success: 'green',
  danger: '#841d64',
  warning: 'yellow',
  info: 'cyan',
  empty: 'white',
}

const TextColors: ThemeColors = {
  primary: 'white',
  secondary: 'black',
  success: 'black',
  danger: 'white',
  warning: 'black',
  info: 'black',
  empty: 'black'
}

const Sizes: ThemeSizes = {
  small: '12px',
  medium: '16px',
  large: '20px',
  extraLarge: '24px'
}

export const DefaultTheme: Theme = {
  bgColor: '#F1F1F1',
  colors: {
    ...Colors,
    // hoverPrimary: '#A5CF0E',
    hoverPrimary: '#2e4a94',
    // disabledPrimary: '#96BD0D26'
    disabledPrimary: '#46b9a026'
  },
  text: {
    colors: TextColors,
    sizes: Sizes,
    defaultFont: '1rem "Catamaran", Gill Sans, My Gill Sans, sans-serif'
    // defaultFont: '1rem "Roboto", Gill Sans, My Gill Sans, sans-serif'
  }
}